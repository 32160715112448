
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import breakpoints from '~/constants/Breakpoints';
import { COLOR_SCHEME_WHITE } from '~/constants/General';
import { imageShape } from '~/constants/PropTypes';

export default {
  props: {
    cards: arrayOf(
      shape({
        heading: oneOfType([string(), nullable()]),
        visual: arrayOf(shape(imageShape).loose),
        entryLink: arrayOf(
          shape({
            title: string(),
            url: string(),
          }).loose
        ),
      }).loose
    ),
  },
  data() {
    return {
      breakpoints,
      COLOR_SCHEME_WHITE,
    };
  },
  computed: {
    vw() {
      return this.$store.state.viewport.width;
    },
  },
};
