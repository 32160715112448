import { render, staticRenderFns } from "./CardPair.vue?vue&type=template&id=620f29b6"
import script from "./CardPair.vue?vue&type=script&lang=js"
export * from "./CardPair.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesVisual: require('/src/components/molecules/Visual.vue').default,AtomsLink: require('/src/components/atoms/Link.vue').default,AtomsButton: require('/src/components/atoms/Button.vue').default,MoleculesSlider: require('/src/components/molecules/Slider.vue').default})
