
import { any, arrayOf, bool, nullable, oneOfType, string } from 'vue-types';

const MAX_FILE_SIZE_MB = 20;

export default {
  inheritAttrs: false,
  props: {
    name: string().isRequired,
    externalError: string().def(''),
    label: oneOfType([string(), nullable()]),
    accept: arrayOf(string()).def([]),
    value: any().def(null),
    small: bool().def(false),
  },
  data() {
    return {
      MAX_FILE_SIZE_MB,
      error: '',
    };
  },
  methods: {
    onEnter(e) {
      if (this.value) {
        e.preventDefault();
      }
    },
    onChange({ target }) {
      const file = target.files[0] || null;

      this.error = this.validateFile(file);

      if (!this.error) {
        this.setFile(file);
      }
    },
    validateFile(file) {
      if (!file) {
        return '';
      }

      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        return this.$t('career.apply.form.errors.upload-file-size', {
          limit: MAX_FILE_SIZE_MB,
        });
      }

      const extension = file.name.split('.').pop();
      if (this.accept?.length && !this.accept.includes(`.${extension}`)) {
        return this.$t('career.apply.form.errors.upload-file-types', {
          types: this.accept.join(', '),
        });
      }
    },
    setFile(file) {
      this.$emit('input', file);
      this.$refs.fileInput.value = '';
    },
  },
};
