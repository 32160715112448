
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import {
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_WHITE,
} from '~/constants/General';
import { imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    thanksForApplying: shape({
      heading: oneOfType([string(), nullable()]),
      subheading: oneOfType([string(), nullable()]),
      cards: arrayOf(
        shape({
          heading: oneOfType([string(), nullable()]),
          visual: arrayOf(shape(imageShape).loose),
          entryLink: arrayOf(
            shape({
              title: string(),
              url: string(),
            }).loose
          ),
        }).loose
      ),
    }).loose,
  },
  data() {
    return {
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_BLUE,
      isMounted: false,
      uploading: false,
      applySuccessful: false,
      overflowing: false,
      activeStep: 0,
      fieldValues: {},
      fieldErrors: {},
      globalError: '',
      notes: '',
      steps: [
        {
          title: this.$t('career.apply.form.steps.start.title'),
          buttonLabel: this.$t('career.apply.form.steps.start.buttonLabel'),
        },
        {
          title: this.$t('career.apply.form.steps.name.title'),
          fields: [
            {
              type: 'text',
              name: 'firstName',
              label: this.$t('career.apply.form.steps.name.firstName.label'),
              placeholder: this.$t(
                'career.apply.form.steps.name.firstName.placeholder'
              ),
              required: true,
            },
            {
              type: 'text',
              name: 'lastName',
              label: this.$t('career.apply.form.steps.name.lastName.label'),
              placeholder: this.$t(
                'career.apply.form.steps.name.lastName.placeholder'
              ),
              required: true,
            },
          ],
        },
        {
          title: this.$t('career.apply.form.steps.contact.title'),
          fields: [
            {
              type: 'email',
              name: 'email',
              label: this.$t('career.apply.form.steps.contact.email.label'),
              placeholder: this.$t(
                'career.apply.form.steps.contact.email.placeholder'
              ),
              required: true,
            },
            {
              type: 'tel',
              name: 'phone',
              label: this.$t('career.apply.form.steps.contact.phone.label'),
              placeholder: this.$t(
                'career.apply.form.steps.contact.phone.placeholder'
              ),
            },
          ],
        },
        {
          title: this.$t('career.apply.form.steps.location.title'),
          fields: [
            {
              type: 'location',
              name: 'location',
              label: this.$t('career.apply.form.steps.location.field.label'),
              placeholder: this.$t(
                'career.apply.form.steps.location.field.placeholder'
              ),
              required: true,
            },
          ],
        },
        {
          title: this.$t('career.apply.form.steps.files.title'),
          subtitle: 'Upload  your resumé and cover letter',
          fields: [
            {
              type: 'file',
              name: 'resume',
              label: this.$t('career.apply.form.steps.files.resume.label'),
              accept: ['.doc', '.docx', '.pdf', '.rtf', '.txt'],
              required: true,
            },
            {
              type: 'file',
              name: 'coverLetter',
              label: this.$t('career.apply.form.steps.files.coverLetter.label'),
              accept: ['.doc', '.docx', '.pdf', '.rtf', '.txt'],
            },
          ],
        },
        {
          title: this.$t('career.apply.form.steps.submit.title'),
          buttonLabel: this.$t('career.apply.form.steps.submit.buttonLabel'),
          fields: [
            {
              type: 'checkbox',
              name: 'terms',
              label: this.$t('career.apply.form.steps.submit.terms.label', {
                href: `/${this.$i18n.locale}/privacy-policy`,
              }),
              required: true,
            },
          ],
          overview: true,
        },
      ],
    };
  },
  computed: {
    active() {
      return this.$store.state.overlays.apply?.active || false;
    },
    careerTitle() {
      return this.$store.state.overlays.apply?.careerTitle;
    },
    greenhouseId() {
      return this.$store.state.overlays.apply?.greenhouseId;
    },
    viewportWidth() {
      return this.$store.state.viewport.width;
    },
    allFields() {
      return this.steps.reduce((prev, step) => {
        if (step.fields?.length) {
          prev.push(...step.fields);
        }
        return prev;
      }, []);
    },
  },
  watch: {
    active(value) {
      if (value) {
        this.applySuccessful = false;
        this.activeStep = 0;
      }
      window.addEventListener('keydown', this.onKeyDown);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;

      if (this.active) {
        window.addEventListener('keydown', this.onKeyDown);
      }
    });
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.tryNext();
      }
    },
    tryNext() {
      const form = this.$refs.forms[this.activeStep];

      if (form && form.requestSubmit) {
        form.requestSubmit();
      } else if (form && !form.checkValidity()) {
        form.reportValidity();
      } else {
        this.onSubmit();
      }
    },
    next() {
      this.activeStep = Math.min(this.activeStep + 1, this.steps.length - 1);

      this.$nextTick(() => {
        const step = this.$refs.steps[this.activeStep];
        if (step.offsetHeight < step.firstChild.offsetHeight) {
          this.overflowing = true;
        } else {
          this.overflowing = false;
        }
      });

      // March 2023: this solves an unexplainable bug in FireFox, 400 is the transition duration
      setTimeout(this.focusFirstInput, 400);
    },
    prev() {
      this.activeStep = Math.max(this.activeStep - 1, 0);
    },
    focusFirstInput() {
      const form = this.$refs.forms[this.activeStep];
      if (form) {
        const input = form.querySelector('input');
        if (input) {
          input.focus();
        }
      }
    },
    onSubmit(e) {
      e?.preventDefault();

      if (this.activeStep < this.steps.length - 1) {
        this.next();
      } else {
        this.apply();
      }
    },
    async apply() {
      this.globalError = '';
      this.fieldErrors = {};

      if (!this.uploading) {
        try {
          this.uploading = true;
          this.$nuxt.$loading.start();

          const payload = new FormData();

          for (const [name, value] of Object.entries(this.fieldValues)) {
            if (name === 'phone') {
              payload.append('phone', `+${value.callingCode} ${value.number}`);
            } else if (name === 'location') {
              payload.append('location', value.location);
              payload.append('latitude', value.lat);
              payload.append('longitude', value.lng);
            } else {
              payload.append(name, value);
            }
          }

          payload.append('id', this.greenhouseId);
          payload.append('notes', this.notes);

          if (this.$route.query?.gh_src) {
            payload.append('gh_src', this.$route.query?.gh_src);
          }

          await this.$cms.post('/greenhouse/jobs/apply', payload);

          this.applySuccessful = true;

          this.$trackingEvent('event', 'career-form', {
            event_category: 'send-successful',
            event_label: this.careerTitle,
          });
        } catch (e) {
          if (e?.response?.data && e.response.status === 400) {
            Object.keys(e.response.data).forEach((key) => {
              const field =
                {
                  first_name: 'firstName',
                  last_name: 'lastName',
                  cover_letter: 'coverLetter',
                }[key] || key;
              this.fieldErrors[field] = this.$first(e.response.data[key]);
            });
          } else if (this.$config.DEV) {
            console.error(e);
            this.globalError = e.message;
          } else {
            this.globalError = this.$t('career.apply.form.errors.global');
            this.$sentry.captureException(e);
          }
        } finally {
          this.uploading = false;
          this.$nuxt.$loading.finish();
        }
      }
    },
  },
};
