import { render, staticRenderFns } from "./Phone.vue?vue&type=template&id=3f4eb750"
import script from "./Phone.vue?vue&type=script&lang=js"
export * from "./Phone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesInputSelect: require('/src/components/molecules/input/Select.vue').default,MoleculesInputText: require('/src/components/molecules/input/Text.vue').default})
