
import { nullable, number, oneOfType, shape, string } from 'vue-types';
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: {
    value: shape({
      location: string(),
      lat: oneOfType([number(), nullable()]),
      lng: oneOfType([number(), nullable()]),
    }).def({
      location: '',
      lat: null,
      lng: null,
    }),
  },
  data() {
    return {
      autocompleteService: null,
      geocoder: null,
      options: [],
      focussedOptionIndex: -1,
      input: null,
    };
  },
  computed: {
    valid() {
      const valid = !!(this.value?.lat && this.value?.lng);

      if (valid) {
        this.input?.setCustomValidity('');
      } else {
        this.input?.setCustomValidity(
          this.$t('career.apply.form.steps.location.invalid')
        );
      }

      return valid;
    },
  },
  async mounted() {
    const loader = new Loader({
      apiKey: this.$config.GOOGLE_API_KEY,
      version: 'weekly',
      libraries: ['places', 'geometry'],
    });

    const { maps } = await loader.load();
    this.autocompleteService = new maps.places.AutocompleteService();
    this.geocoder = new maps.Geocoder();
  },
  methods: {
    onInput(value) {
      if (value !== this.value.location) {
        this.$emit('input', {
          location: value,
          lng: null,
          lat: null,
        });

        this.setOptions(value);
      }
    },
    async onOptionSelect({ label, id }, test = false) {
      this.$emit('input', {
        location: label,
        lng: this.value.lng,
        lat: this.value.lat,
      });

      this.options = [];
      this.setFocussedOptionIndex(-1);

      try {
        const { results } = await this.geocoder.geocode({ placeId: id });
        const { lng, lat } = results[0].geometry.location;
        this.$emit('input', {
          location: label,
          lng: lng(),
          lat: lat(),
        });
      } catch (error) {
        console.error(error);
        this.$emit('input', {
          location: this.value.location,
          lng: null,
          lat: null,
        });
      }
    },
    onBlur() {
      this.setFocussedOptionIndex(-1);
    },
    onInputKeydown(e) {
      e.stopPropagation();

      if (e.key === 'ArrowDown' || e.key === 'Tab') {
        e.preventDefault();
        this.setFocussedOptionIndex(this.focussedOptionIndex + 1);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.setFocussedOptionIndex(this.focussedOptionIndex - 1);
      } else if (e.key === 'Enter') {
        if (this.focussedOptionIndex >= 0) {
          e.preventDefault();
          this.onOptionSelect(this.options[this.focussedOptionIndex]);
        } else if (this.options.length) {
          e.preventDefault();
          this.onOptionSelect(this.options[0]);
        }
      } else {
        this.setFocussedOptionIndex(-1);
      }
    },
    setFocussedOptionIndex(i) {
      this.focussedOptionIndex = Math.min(
        Math.max(i, -1),
        this.options.length - 1
      );
    },
    setOptions(value) {
      if (this.autocompleteService && value) {
        this.autocompleteService.getQueryPredictions(
          { input: value, fields: ['description', 'place_id', 'types'] },
          (predictions) => {
            if (predictions) {
              this.options = predictions
                .filter(
                  ({ types }) =>
                    Array.isArray(types) && types.includes('locality')
                )
                .map(({ description: label, place_id: id }) => ({ id, label }));
            }
          }
        );
      } else {
        this.options = [];
      }
    },
  },
};
