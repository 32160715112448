
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { COLOR_SCHEME_WHITE } from '~/constants/General';
import { imageShape } from '~/constants/PropTypes';
export default {
  props: {
    heading: string(),
    subheading: string(),
    cards: arrayOf(
      shape({
        heading: oneOfType([string(), nullable()]),
        body: oneOfType([string(), nullable()]),
        visual: arrayOf(shape(imageShape).loose),
        entryLink: arrayOf(
          shape({
            title: string(),
            url: string(),
          }).loose
        ),
      }).loose
    ),
  },
  data() {
    return {
      COLOR_SCHEME_WHITE,
    };
  },
};
