
import { nullable, oneOfType, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    type: string().def('text'),
    name: string().isRequired,
    label: oneOfType([string(), nullable()]),
    value: oneOfType([string(), nullable()]),
    error: oneOfType([string(), nullable()]),
  },
  data() {
    return {
      mirroredValue: this.value,
      valid: false,
    };
  },
  watch: {
    value(newValue) {
      this.mirroredValue = newValue;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.input);
    });
  },
  methods: {
    onBlur(e) {
      this.valid = !e.target.validationMessage;
      this.$emit('blur', e);
    },
    updateValue({ target }) {
      this.valid = false;
      this.mirroredValue = target.value;
      this.$emit('input', target.value);
    },
  },
};
