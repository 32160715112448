
import { arrayOf, bool, nullable, oneOfType, shape, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    name: string().isRequired,
    label: oneOfType([string(), nullable()]),
    value: oneOfType([string(), nullable()]),
    error: oneOfType([string(), nullable()]),
    valuePrefix: oneOfType([string(), nullable()]),
    valueSuffix: oneOfType([string(), nullable()]),
    options: arrayOf(
      shape({
        value: string(),
        label: string(),
        disabled: bool().def(false),
      })
    ).def([]),
  },
};
